import type { ZodError } from 'zod';
import type { LeapError, GraphQLError } from '-/leap-models';

export const fieldErrorMessage = (errors: LeapError[], field: string) => {
  return errors?.find((error: LeapError) => error.field === field);
};

export const fieldErrorMessages = (errors: LeapError[], field: string) => {
  return errors?.filter((error: LeapError) => error.field === field);
};

export const fieldsErrorMessages = (errors: LeapError[], fields: string[]) => {
  return fields
    .map((field) => fieldErrorMessage(errors, field))
    .filter((field) => field)
    .join(', ');
};

export const baseErrorMessages = (errors: LeapError[]) => {
  if (!errors) {
    return [];
  }
  return errors.filter((error: LeapError) => error.field === 'base');
};

export const parseBaseErrorsFromResponse = (errs: GraphQLError[]) => {
  let errors = errs.map((graphErr) => ({
    field: 'base',
    message: graphErr.message,
    code: graphErr.extensions.code,
  }));
  errors = errors.filter((err) => err.code === 'USER_ERROR');
  return { errors };
};

export const parseZodErrors = (
  zodError: ZodError,
  messageFunction: (translationKey: string) => string
): LeapError[] => {
  return zodError.errors.map((err) => ({
    field: err.path
      .map((path, index) => (index === 0 ? path : `[${path}]`))
      .join(''),
    code: err.code,
    message: messageFunction(`${err.path.at(-1)}.${err.code}`),
  }));
};

export const logErrors = (errors: unknown, sentry: any) => {
  console.error(errors);
  try {
    sentry.captureException(errors);
  } catch (e) {
    console.error(e);
    // do nothing
  }
};

export const logBoundaryErrors = (errors: unknown, sentry: any) => {
  console.error(errors);
  try {
    sentry.captureRemixErrorBoundaryError(errors);
  } catch (e) {
    console.error(e);
    // do nothing
  }
};

export const notFoundError = () =>
  new Response('Not Found', {
    status: 404,
    statusText: 'Not Found',
  });
